@font-face {
  font-family: 'SpoqaBold';
  src: url('./SpoqaHanSansNeo-Bold.ttf');
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: 'Poppin400';
  src: url('./Poppins-Regular.ttf');
}

@font-face {
  font-family: 'SpoqaMedium';
  src: url('./SpoqaHanSansNeo-Medium.ttf');
}

@font-face {
  font-family: 'Poppin500';
  src: url('./Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppin700';
  src: url('./Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Odibee';
  src: url('./OdibeeSans-Regular.ttf');
}

@font-face {
  font-family: 'NotoSansBold';
  src: url('./NotoSans-Bold.ttf');
}

@font-face {
  font-family: 'OneMobile';
  src: url('./ONE-Mobile-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'OneMobile';
  src: url('./ONE-Mobile-Bold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'OneMobileTitle';
  src: url('./ONE-Mobile-Title.ttf');
}
